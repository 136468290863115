import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import * as UserAction from './user.actions';
import { VbAuthBrowserService } from '../../auth-browser.service';

@Injectable()
export class VbAuthStateUserEffects {
    register$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserAction.register),
            switchMap(({ email }) => {
                return this._authService.subscribe(email).pipe(take(1));
            }),
            map((user) => UserAction.registerSuccess({ user })),
            catchError((error) => of(UserAction.registerError({ error })))
        )
    );

    // onAuthStateChange$ = createEffect(() =>
    //     authState(this._auth).pipe(
    //         map((user) => {
    //             if (!!user?.uid) {
    //                 return UserActions.fetchUser();
    //             }

    //             return UserActions.resetSession();
    //         })
    //     )
    // );

    // onFetchUser$ = createEffect(() =>
    //     this.dataPersistence.fetch<typeof UserActions.fetchUser>(
    //         UserActions.fetchUser,
    //         {
    //             run: (action, state: UserFeature.UserPartialState) =>
    //                 this._authService
    //                     .me()
    //                     .pipe(map((user) => UserActions.setUser({ user }))),
    //             onError: (action, error) => UserActions.resetSession()
    //         }
    //     )
    // );

    // logout$ = createEffect(
    //     () =>
    //         this.actions$.pipe(
    //             ofType(UserActions.logout),
    //             switchMap(() => this._authService.logout())
    //         ),
    //     { dispatch: false }
    // );

    constructor(
        private readonly actions$: Actions,
        private _authService: VbAuthBrowserService
    ) {}
}

import { HttpErrorResponse } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';
import firebase from 'firebase/compat/app';

import { IFirebaseUser } from '../../../interfaces/user.interfaces';

export const register = createAction('[User/Registration] Register', props<{ email: string }>());
export const registerSuccess = createAction(
    '[User/Registration] Register Success',
    props<{ user: any }>()
);
export const registerError = createAction(
    '[User/Registration] Register Error',
    props<{ error: HttpErrorResponse }>()
);

export const fetchUser = createAction('[Auth/User] Fetch User');
export const setUser = createAction('[Auth/User] Set User', props<{ user?: IFirebaseUser }>());
export const resetSession = createAction('[Auth/User] Reset Session');

/**
 * Login
 */
export const login = createAction(
    '[Auth/User] Login',
    props<{ email: string; password: string }>()
);
export const loginSuccess = createAction('[Auth/User] Login Success');
export const loginError = createAction(
    '[Auth/User] Login Error',
    props<{ error: firebase.auth.Error }>()
);

/**
 * Logout
 */
export const logout = createAction('[Auth/User] Logout');

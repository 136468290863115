import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as fromState from './reducers';
import { VbAuthStateUserEffects } from './user/user.effects';

@NgModule({
    imports: [
        MatSnackBarModule,

        StoreModule.forFeature(fromState.FEATURE_KEY, fromState.reducers),
        EffectsModule.forFeature([VbAuthStateUserEffects])
    ]
})
export class VbAuthBrowserStateModule {}

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { VbAuthBrowserModuleOptions } from './auth-browser-options';
import { VbAuthBrowserFacadeService } from './auth-browser.facade';
import { VbAuthBrowserService } from './auth-browser.service';
import { VbAuthBrowserStateModule } from './state/auth-state.module';

@NgModule({ declarations: [],
    exports: [], imports: [VbAuthBrowserStateModule], providers: [VbAuthBrowserService, VbAuthBrowserFacadeService, provideHttpClient(withInterceptorsFromDi())] })
export class VbAuthBrowserModule {
    static forRoot(
        moduleOptions: VbAuthBrowserModuleOptions
    ): ModuleWithProviders<VbAuthBrowserModule> {
        return {
            ngModule: VbAuthBrowserModule,
            providers: [
                {
                    provide: VbAuthBrowserModuleOptions,
                    useValue: moduleOptions
                }
            ]
        };
    }
}

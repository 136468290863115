import { Injectable } from '@angular/core';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';

import * as Reducers from './state/reducers';
import * as UserActions from './state/user/user.actions';

@Injectable({ providedIn: 'root' })
export class VbAuthBrowserFacadeService {
    ready$ = this.store.select(Reducers.queryUserReady);
    loading$ = this.store.select(Reducers.queryUserLoading);
    userError$ = this.store.select(Reducers.queryUserError);
    currentUser$ = this.store.select(Reducers.queryCurrentUser);

    constructor(
        private store: Store<Reducers.State>,
        private actions$: Actions
    ) {}

    register(email: string) {
        this.store.dispatch(UserActions.register({ email }));
    }

    logout() {
        this.store.dispatch(UserActions.logout());
    }

    getNextUserAction(name: keyof typeof UserActions) {
        if (!name) {
            return of(null);
        }

        if (!UserActions[name]) {
            console.warn(`The user action ${name} was not found.`);

            return of(null);
        }

        return this.actions$.pipe(ofType(UserActions[name]), take(1));
    }

    // register(email: string, password: string) {
    //     this.store.dispatch(UserActions.register({ email, password }));
    // }

    // login(email: string, password: string) {
    //     this.store.dispatch(UserActions.login({ email, password }));
    // }

    // logout() {
    //     this.store.dispatch(UserActions.logout());
    // }

    // sendLoginLink(
    //     email: string,
    //     purpose: string = 'login',
    //     returnUrl?: string
    // ) {
    //     this.store.dispatch(
    //         UserActions.sendLoginLink({ email, purpose, returnUrl })
    //     );
    // }

    // validateLoginLink() {
    //     this.store.dispatch(UserActions.validateLoginLink());
    // }

    // resetPassword(email: string) {
    //     this.store.dispatch(UserActions.resetPassword({ email }));
    // }
}

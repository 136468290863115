import { HttpErrorResponse } from '@angular/common/http';

import { Action, createReducer, on } from '@ngrx/store';
import firebase from 'firebase/compat/app';

import * as UserActions from './user.actions';
import { IFirebaseUser } from '../../../interfaces/user.interfaces';

export const FEATURE_KEY = 'user';

export interface State {
  ready: boolean;
  loading: boolean;
  profile?: IFirebaseUser;
  error?: HttpErrorResponse | firebase.auth.Error;
}

export interface UserPartialState {
  readonly [FEATURE_KEY]: State;
}

export const initialState: State = {
  ready: false,
  loading: false,
  profile: undefined,
  error: undefined,
};

const activeReducer = createReducer(
  initialState,

  on(UserActions.resetSession, (state, action) => {
    return {
      ...state,
      loading: false,
      ready: true,
      profile: undefined,
    };
  }),

  on(UserActions.setUser, (state, action) => {
    return {
      ...state,
      loading: false,
      ready: true,
      profile: action.user,
    };
  }),

  // on(UserActions.register, (state) => ({
  //     ...state,
  //     profile: undefined,
  //     loading: true,
  //     error: undefined
  // })),

  // on(UserActions.registerSuccess, (state, action) => ({
  //     ...state,
  //     // user: action.user,
  //     loading: false
  // })),

  // on(UserActions.registerError, (state, action) => ({
  //     ...state,
  //     loading: false,
  //     error: action.error
  // })),
  // /**
  //  * Register and Login
  //  */
  // on(
  //     UserActions.login,
  //     UserActions.register,
  //     UserActions.sendLoginLink,
  //     UserActions.validateLoginLink,
  //     (state) => ({
  //         ...state,
  //         error: undefined,
  //         loading: true
  //     })
  // ),
  // on(
  //     UserActions.loginSuccess,
  //     UserActions.registerSuccess,
  //     UserActions.sendLoginLinkSuccess,
  //     (state) => ({
  //         ...state,
  //         error: undefined,
  //         loading: false
  //     })
  // ),

  // /**
  //  * Error handling
  //  */
  // on(UserActions.registerError, UserActions.loginError, (state, action) => {
  //     const { error } = action;

  //     console.log('ERROR CODE:', error.value);
  //     console.log('ERROR MESSAGE:', error.message);

  //     return {
  //         ...state,
  //         loading: false,
  //         error
  //     };
  // }),

  // /**
  //  * Logout
  //  */
  // on(UserActions.logout, (state) => ({
  //     ...state,
  //     loading: false,
  //     error: null
  // }))
);

export function reducer(state: State | undefined, action: Action) {
  return activeReducer(state, action);
}

/*
 * Copyright (C) Vinbörsen AB - All Rights Reserved
 *
 * Proprietary and confidential. This source code is protected under
 * international copyright law. Unauthorized copying of this file, via any
 * medium is strictly prohibited.
 */
import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable, PLATFORM_ID, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { firstValueFrom } from 'rxjs';
import { distinctUntilKeyChanged, filter } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { AppHelperService } from '../../app-helper.service';
import { RootFacade } from '../../state/root/root.facade';
import { SignupStatus } from '../../state/root/root.reducer';
import { AppTrackerService } from '../tracker/tracker.service';
import { AppSharedNewsletterDialogComponent } from './dialog/signup-dialog.component';
import { AppSharedNewsletterWelcomeDialogComponent } from './dialog/welcome-dialog.component';

const REGISTER_PROMPTED_COOKIE_NAME = '_vb-register-prompted';

export interface IMemberTraits {
  email: string;
  phone?: string;
}

export interface IMember extends IMemberTraits {
  anonymous_id: string;
  cio_id: string;
  created_at: string;
  id: string;
  unsubscribed: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  private readonly route = inject(ActivatedRoute);
  private readonly http = inject(HttpClient);
  private readonly tracker = inject(AppTrackerService);
  private readonly dialog = inject(MatDialog);
  private readonly platformId = inject(PLATFORM_ID);
  private readonly rootFacade = inject(RootFacade);
  private readonly appHelper = inject(AppHelperService);
  private readonly cookieService = inject(CookieService);

  private readonly API_BASE_URL = environment.api.base;

  private readonly _ready = signal<boolean>(false);
  private readonly _loading = signal<boolean>(false);
  private readonly _member = signal<IMember | undefined>(undefined);

  ready = this._ready.asReadonly();
  loading = this._loading.asReadonly();
  member = this._member.asReadonly();
  member$ = toObservable(this._member);

  async init(): Promise<IMember | undefined> {
    const queryCId = this.checkForCIdInUrl();

    this.listenForMemberChanges();

    if (queryCId) {
      await this.fetchUserInfo();
    }

    this._ready.set(true);

    return this.member();
  }

  private checkForCIdInUrl(): string | undefined {
    return this.route.snapshot.queryParamMap.get('_cio_id') ?? undefined;
  }

  private listenForMemberChanges() {
    this.member$
      .pipe(
        filter((member) => !!member?.id),
        distinctUntilKeyChanged('id'),
      )
      .subscribe((member) => {
        console.log('Member has changed', member);

        this.identifyMember(member);
      });
  }

  openWelcomeDialog() {
    this.dialog.open(AppSharedNewsletterWelcomeDialogComponent, {
      width: '736px',
      maxWidth: 'calc(100vw - 40px)',
      hasBackdrop: true,
    });
  }

  openRegisterDialog() {
    this.dialog.open(AppSharedNewsletterDialogComponent, {
      width: '916px',
      maxWidth: 'calc(100vw - 40px)',
      hasBackdrop: true,
    });

    this.rootFacade.setSignupStatus(SignupStatus.PROMPTED);

    this.setRegisterCookie();
  }

  isRegistrationPrompted() {
    if (isPlatformServer(this.platformId)) {
      return true;
    }

    return this.cookieService.check(REGISTER_PROMPTED_COOKIE_NAME);
  }

  setRegisterCookie() {
    this.setCookie(REGISTER_PROMPTED_COOKIE_NAME, 'v1');
  }

  private setCookie(name: string, value: string) {
    const _location = this.appHelper.getCurrentLocation();

    this.cookieService.set(
      name,
      value,
      30,
      '/',
      _location.hostname,
      _location.protocol === 'https',
      'Lax',
    );
  }

  async subscribe(traits: IMemberTraits) {
    console.log('Subscribe user with traits:', traits);

    this._loading.set(true);

    const member = await firstValueFrom(
      this.http.post<IMember>(
        `${this.API_BASE_URL}/api/members/subscribe`,
        traits,
      ),
    );
    //   .catch((err) => {
    //   console.log('Error subscribing user:', err);
    //
    //   return undefined;
    // });

    console.log('User subscribed:', member);

    this.rootFacade.setSignupStatus(SignupStatus.COMPLETED);

    this._loading.set(false);
    this._member.set(member);

    return member;
  }

  private async identifyMember(member: IMember) {
    if (!member?.id) {
      return;
    }

    const memberId = member.id;

    // const trackedUser = await this.tracker.user();
    // const trackedUserId = trackedUser.id();

    // Let's wait with this, could break the chain if multiple users use the same computer.
    // if (trackedUserId && trackedUserId !== memberId) {
    //   await this.tracker.alias(memberId, trackedUserId);
    // }

    await this.tracker.identify(memberId, {
      customerIoId: member.cio_id,
    });
  }

  async fetchUserInfo() {
    const cid = this.route.snapshot.queryParamMap.get('_cio_id');

    const member = await firstValueFrom(
      this.http.get<IMember>(`${this.API_BASE_URL}/api/members/me`, {
        params: {
          cid,
        },
      }),
    ).catch((err) => {
      console.log('Error fetching User:', err);
    });

    this._member.set(member || undefined);
  }
}

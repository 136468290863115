import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-newsletter-welcome-dialog',
  template: `
    <div class="mat-dialog-header">
      <div mat-dialog-title>Välkommen till Vinbörsen!</div>
    </div>

    <div mat-dialog-content>
      <p style="margin: 16px 0;">
        Du är nu registrerad som prenumerant. Om du har frågor, förslag eller
        åsikter kring våra utskick når du oss alltid på
        <a href="mailto: info@vinborsen.se">info&#64;vinborsen.se</a>.
      </p>
    </div>

    <div mat-dialog-actions>
      <button mat-flat-button color="accent" (click)="close()">
        <span>Stäng</span>
      </button>
    </div>
  `,
})
export class AppSharedNewsletterWelcomeDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<AppSharedNewsletterWelcomeDialogComponent>,
  ) {}

  close() {
    this.dialogRef.close();
  }
}

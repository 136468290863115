import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { Subject } from 'rxjs';

import { VbAuthBrowserFacadeService } from '@vb/auth/browser';

import { AppTrackerService } from '../../tracker/tracker.service';

@Component({
  selector: 'app-newsletter-signup-dialog',
  styleUrls: ['./signup-dialog.component.scss'],
  template: `
    <div class="newsletter-dialog">
      <button mat-icon-button (click)="close()" class="newsletter-close-button">
        <mat-icon>close</mat-icon>
      </button>

      <div class="newsletter-content">
        <h2 *ngIf="title">
          {{ title }}
        </h2>
        <p *ngIf="description">
          {{ description }}
        </p>
        <app-newsletter-form (completed)="close()"></app-newsletter-form>
      </div>

      <div class="newsletter-image">
        <ui-image
          src="https://vinborsen-se.imgix.net/static/newsletter-signup-image.jpg"
          [lazyload]="true"
          [sizes]="{
            xs: [400, 200],
            sm: [900, 300],
            md: [410, 440],
            lg: [410, 440],
          }"
          [fit]="'crop'"
        ></ui-image>
      </div>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class AppSharedNewsletterDialogComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  @Input() title: string | boolean = 'Få alla nya fynd direkt i mejlen.';
  @Input() description: string | boolean =
    'Bli medlem så får du alla tips om nya prissänkta och prisvärda produkter direkt till din mejl.';

  @Input() force = false;
  @Input() color: 'green' | undefined;

  @Output() readonly completed = new EventEmitter<void>();

  @HostBinding('attr.color') get attrBgColor() {
    return this.color || null;
  }

  loading$ = this._authFacade.loading$;

  constructor(
    private _dialogRef: MatDialogRef<AppSharedNewsletterDialogComponent>,
    private _authFacade: VbAuthBrowserFacadeService,
    private _tracker: AppTrackerService,
  ) {}

  ngOnInit() {
    this._tracker.track('User Registration Opened');
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  close() {
    this._dialogRef.close();
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { VbAuthBrowserModuleOptions } from './auth-browser-options';

@Injectable()
export class VbAuthBrowserService {
    onAuthStateChanged = new BehaviorSubject(undefined);

    constructor(
        private readonly moduleOptions: VbAuthBrowserModuleOptions,
        private _http: HttpClient
    ) {}

    subscribe(email: string): Observable<any> {
        email = email.replace(/\+/g, '%2B');

        const baseUrl = this.moduleOptions.apiBase;
        const params = new HttpParams({
            fromObject: { email } as any
        });

        return this._http.post<any>(`${baseUrl}members/subscribe`, params, {
            withCredentials: true
        });
    }

    me(): Observable<any> {
        const baseUrl = this.moduleOptions.apiBase;

        return this._http.get<any>(`${baseUrl}members/me`);
    }
}

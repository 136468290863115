import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as UserReducer from './user/user.reducer';

export const FEATURE_KEY = 'auth';

export interface State {
    user: UserReducer.State;
}

export const reducers: ActionReducerMap<State> = {
    user: UserReducer.reducer
};

const selectAuthState = createFeatureSelector<State>(FEATURE_KEY);

export const selectAuthUserState = createSelector(
    selectAuthState,
    (state) => state[UserReducer.FEATURE_KEY]
);
export const queryUserReady = createSelector(selectAuthUserState, (state) => state.ready);
export const queryUserLoading = createSelector(selectAuthUserState, (state) => state.loading);

export const queryUserError = createSelector(selectAuthUserState, (state) => state.error);

export const queryCurrentUser = createSelector(selectAuthUserState, (state) => state.profile);
